<template>
    <div class="bg-gray-900 p-4 rounded-lg shadow-md p-9">
      <h2 class="text-xl font-semibold text-white">Kernkompetenzen</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
        <div v-for="(competence, index) in competences" :key="index" class="flex items-center bg-zinc-950 p-4 rounded-lg shadow-sm">
          <div class="flex-shrink-0 text-white">
            <component :is="competence.icon" class="h-4 w-4" />
          </div>
          <div class="ml-4">
            <p class="text-white font-medium">{{ competence.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { CodeBracketIcon } from '@heroicons/vue/24/outline'
  
  export default {
    name: 'CompetenceCard',
    data() {
      return {
        competences: [
          { name: 'Full-Stack-Entwicklung', icon: CodeBracketIcon },
          { name: 'CI/CD', icon: CodeBracketIcon },
          { name: 'Angular', icon: CodeBracketIcon },
          { name: 'Vue.js', icon: CodeBracketIcon },
          { name: 'Node.js', icon: CodeBracketIcon },
          { name: '.NET', icon: CodeBracketIcon }
          // Weitere Skills hier
        ]
      }
    }
  }
  </script>