<template>
  <div class="flex flex-col md:flex-row h-screen bg-gray-950">
    <!-- Linke Seite -->
    <aside
      class="p-8 md:w-[50%] md:fixed md:left-0 md:top-0 md:h-full md:overflow-y-auto flex flex-col justify-between items-center">
      <div class="flex flex-col justify-center items-center flex-grow">
        <div>
          <h1 class="text-5xl font-bold text-white">Alexander Gawrilenko</h1>
          <h2 class="text-2xl text-gray-300">Full-Stack-Entwickler</h2>
          <div class="mt-4 space-y-2">
            <div class="flex items-center text-gray-300">
              <EnvelopeIcon class="h-5 w-5 mr-2" />
              <p><a href="mailto:dev@gawrilenko.de">dev@gawrilenko.de</a></p>
            </div>
            <div class="flex items-center text-gray-300">
              <PhoneIcon class="h-5 w-5 mr-2" />
              <p><a href="tel:+4915121021614">+49 1512 1021614</a></p>
            </div>
          </div>
        </div>
      </div>

      <!-- Technologies and Links Section -->
      <div class="flex-grow-0 text-center mb-8">
        <p class="text-md text-gray-400 flex items-center justify-center space-x-4">
          <span>Built with</span>
          <a href="https://vuejs.org/" class="flex items-center space-x-2">
            <img src="./assets/images/vue.svg" alt="Vue.js" class="w-4 h-4" />
            <span>Vue.js</span>
          </a>
          <a href="https://tailwindcss.com/" class="flex items-center space-x-2">
            <img src="./assets/images/tailwind-css.svg" alt="TailwindCSS" class="w-4 h-4" />
            <span>TailwindCSS</span>
          </a>
          <a href="https://heroicons.com/" class="flex items-center space-x-2">
            <img src="./assets/images/heroicons.png" alt="Heroicons" class="w-4 h-4" />
            <span>Heroicons</span>
          </a>
        </p>
        <div class="mt-6 space-x-4">
          <button @click="openModal('privacy')" class="text-gray-300 hover:underline">Datenschutzerklärung</button>
          <button @click="openModal('impressum')" class="text-gray-300 hover:underline">Impressum</button>
        </div>
      </div>
    </aside>

    <!-- Rechte Seite -->
    <main class="flex-1 md:ml-[50%] p-10 overflow-y-auto">
      <p class="text-2xl font-bold text-white">Diese Website befindet sich aktuell in Bearbeitung</p>
      <div class="mt-8">
        <CompetenceCard class="opacity-0 fade-in-up delay-1" />
        <LanguagesCard class="mt-10 opacity-0 fade-in-up delay-2" />
        <TechCard class="mt-10 opacity-0 fade-in-up delay-3" />
        <DatabasesCard class="mt-10 opacity-0 fade-in-up delay-4" />
        <ToolsCard class="mt-10 opacity-0 fade-in-up delay-5" />
      </div>
    </main>

    <!-- Modal für Datenschutzerklärung -->
    <div v-if="modal === 'privacy'" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      @click="closeModal">
      <div class="bg-gray-800 p-6 rounded-lg shadow-lg max-w-2xl max-h-[80%] mx-auto mt-10 mb-10 overflow-y-auto"
        @click.stop>
        <h2 class="text-xl font-bold text-white">Datenschutzerklärung</h2>
        <div class="mt-4 text-gray-300">
          <h2 class="font-bold">1. Datenschutz auf einen Blick</h2><br />
          <h3 class="font-bold">Allgemeine Hinweise</h3>
          <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten
            passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
            identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter
            diesem Text aufgeführten Datenschutzerklärung.</p><br />
          <h3 class="font-bold">Datenerfassung auf dieser Website</h3>
          <h4 class="font-bold">Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
          <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie
            dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.</p><br />
          <h4 class="font-bold">Wie erfassen wir Ihre Daten?</h4>
          <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um
            Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
          <p>Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere
            IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit
            des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.</p>
          <br />
          <h4 class="font-bold">Wofür nutzen wir Ihre Daten?</h4>
          <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere
            Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p><br />
          <h4 class="font-bold">Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
          <p>Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
            gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
            Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können
            Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten
            Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht
            Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</p>
          <p>Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.</p><br />
          <h2 class="font-bold">2. Hosting</h2>
          <p>Wir hosten die Inhalte unserer Website bei folgendem Anbieter:</p><br />
          <h3 class="font-bold">Externes Hosting</h3>
          <p>Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden,
            werden auf den Servern des Hosters / der Hoster gespeichert. Hierbei kann es sich v.a. um IP-Adressen,
            Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und
            sonstige Daten, die über eine Website generiert werden, handeln.</p>
          <p>Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden
            Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
            Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
            Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
            Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von
            Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z.B. Device-Fingerprinting) im Sinne
            des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>
          <p>Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
            Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.</p><br />
          <p>Wir setzen folgende(n) Hoster ein:</p>
          <p>IP-Projects GmbH & Co. KG<br />
            Am Vogelherd 14<br />
            97295 Waldbrunn</p><br />
          <h4 class="font-bold">Auftragsverarbeitung</h4>
          <p>Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes
            geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der
            gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen
            und unter Einhaltung der DSGVO verarbeitet.</p><br />
          <h2 class="font-bold">3. Allgemeine Hinweise und Pflichtinformationen</h2><br />
          <h3 class="font-bold">Datenschutz</h3>
          <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
            personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
            Datenschutzerklärung.</p>
          <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten
            sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck
            das geschieht.</p>
          <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
            Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.</p><br />
          <h3>Hinweis zur verantwortlichen Stelle</h3>
          <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p><br />
          <p>Alexander Gawrilenko<br />
            Leibnizstr. 11<br />
            63150 Heusenstamm</p>
          <p>Telefon: +49 (0) 1512 1021614<br />
            E-Mail: dev@gawrilenko.de</p><br />
          <p>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen
            über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.)
            entscheidet.</p><br />
          <h3 class="font-bold">Speicherdauer</h3>
          <p>Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre
            personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes
            Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
            gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen
            Daten haben (z.B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
            Löschung nach Fortfall dieser Gründe.</p><br />
          <h3 class="font-bold">Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website
          </h3>
          <p>Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf
            Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien
            nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung
            personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49
            Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr
            Endgerät (z.B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf
            Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
            Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten
            auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur
            Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
            Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f
            DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen
            dieser Datenschutzerklärung informiert.</p><br />
          <h3 class="font-bold">Empfänger von personenbezogenen Daten</h3>
          <p>Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit verschiedenen externen Stellen zusammen. Dabei ist
            teilweise auch eine Übermittlung von personenbezogenen Daten an diese externen Stellen erforderlich. Wir
            geben personenbezogene Daten nur dann an externe Stellen weiter, wenn dies im Rahmen einer Vertragserfüllung
            erforderlich ist, wenn wir gesetzlich hierzu verpflichtet sind (z.B. Weitergabe von Daten an
            Steuerbehörden), wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f DSGVO an der Weitergabe haben
            oder wenn eine sonstige Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von Auftragsverarbeitern
            geben wir personenbezogene Daten unserer Kunden nur auf Grundlage eines gültigen Vertrags über
            Auftragsverarbeitung weiter. Im Falle einer gemeinsamen Verarbeitung wird ein Vertrag über gemeinsame
            Verarbeitung geschlossen.</p><br />
          <h3 class="font-bold">Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
          <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine
            bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
            Datenverarbeitung bleibt vom Widerruf unberührt.</p><br />
          <h3>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)
          </h3>
          <p class="font-bold">WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT,
            HABEN SIE JEDERZEIT
            DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
            PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
            PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
            DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN
            NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
            NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER
            GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).</p>
          <p class="font-bold">WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO HABEN
            SIE DAS RECHT,
            JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER
            WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
            STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
            DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).</p><br />
          <h3 class="font-bold">Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
          <p>Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
            oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
            verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.</p><br />
          <h3 class="font-bold">Recht auf Datenübertragbarkeit</h3>
          <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags
            automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format
            aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
            verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p><br />
          <h3 class="font-bold">Auskunft, Berichtigung und Löschung</h3>
          <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft
            über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
            Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren
            Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.</p><br />
          <h3 class="font-bold">Recht auf Einschränkung der Verarbeitung</h3>
          <p>Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu
            können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden
            Fällen:</p>
          <ul>
            <li>Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir
              in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung
              der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der
              Löschung die Einschränkung der Datenverarbeitung verlangen.</li>
            <li>Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung
              oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die
              Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
            <li>Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren
              und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
              haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
          </ul>
          <p>Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten - von ihrer
            Speicherung abgesehen - nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von
            Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus
            Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet
            werden.</p><br />
          <h3 class="font-bold">SSL- bzw. TLS-Verschlüsselung</h3>
          <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum
            Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
            TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers
            von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
          <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht
            von Dritten mitgelesen werden.</p><br />
          <h2 class="font-bold">4. Datenerfassung auf dieser Website</h2><br />
          <h3 class="font-bold">Cookies</h3>
          <p>Unsere Website verwendet keine Cookies. Cookies sind kleine Textdateien, die auf Ihrem Endgerät gespeichert
            werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Da wir auf den Einsatz von
            Cookies verzichten, findet keine Speicherung oder Auswertung von personenbezogenen Daten durch Cookies
            statt.</p><br />
          <h3 class="font-bold">Server-Log-Dateien</h3>
          <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien,
            die Ihr Browser automatisch an uns übermittelt. Dies sind:</p><br />
          <ul class="list-inside list-disc">
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
          </ul><br />
          <p>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</p><br />
          <p>Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat
            ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website -
            hierzu müssen die Server-Log-Files erfasst werden.</p>
          <br />
          <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
        </div>
        <button @click="closeModal" class="mt-4 text-blue-500 hover:underline">Schließen</button>
      </div>
    </div>

    <!-- Modal für Impressum -->
    <div v-if="modal === 'impressum'" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      @click="closeModal">
      <div class="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg mx-auto" @click.stop>
        <h2 class="text-xl font-bold text-gray-900 dark:text-white">Impressum</h2>
        <div class="mt-4 text-gray-300">
          <h3 class="font-bold">Angaben gemäß § 5 TMG</h3>
          <p>Alexander Gawrilenko<br />
            Leibnizstr. 11<br />
            63150 Heusenstamm</p>
          <br />
          <h3 class="font-bold">Kontakt</h3>
          <p>Telefon: +49 (0) 1512 1021614<br />
            E-Mail: dev@gawrilenko.de</p>
          <br />
          <h3 class="font-bold">Umsatzsteuer-ID</h3>
          <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:<br />
            DE323639634</p>
          <br />
          <h3 class="font-bold">EU-Streitschlichtung</h3>
          <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
              href="https://ec.europa.eu/consumers/odr/" target="_blank"
              rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie
            oben im Impressum.</p>
          <br />
          <h3 class="font-bold">Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h3>
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
            Verbraucherschlichtungsstelle
            teilzunehmen.</p>

        </div>
        <button @click="closeModal" class="mt-4 text-blue-500 hover:underline">Schließen</button>
      </div>
    </div>
  </div>
</template>

<script>
import TechCard from './components/TechCard.vue'
import CompetenceCard from './components/CompetenceCard.vue'
import DatabasesCard from './components/DatabasesCard.vue'
import ToolsCard from './components/ToolsCard.vue'
import LanguagesCard from './components/LanguagesCard.vue'
import { EnvelopeIcon, PhoneIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'App',
  components: {
    TechCard,
    CompetenceCard,
    DatabasesCard,
    ToolsCard,
    LanguagesCard,
    EnvelopeIcon,
    PhoneIcon
  },
  data() {
    return {
      modal: null
    }
  },
  methods: {
    openModal(type) {
      this.modal = type
    },
    closeModal() {
      this.modal = null
    }
  }
}
</script>

<style>
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation: fadeInUp 0.75s ease-out forwards;
}

.delay-1 {
  animation-delay: 0.1s;
}

.delay-2 {
  animation-delay: 0.2s;
}

.delay-3 {
  animation-delay: 0.3s;
}

.delay-4 {
  animation-delay: 0.4s;
}

.delay-5 {
  animation-delay: 0.5s;
}

.delay-6 {
  animation-delay: 0.6s;
}
</style>