<template>
  <div class="bg-gray-900 p-4 rounded-lg shadow-md p-9">
    <h2 class="text-xl font-semibold text-white">Frameworks/Technologien</h2>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
      <div v-for="(skill, index) in skills" :key="index" class="flex items-center bg-zinc-950 p-4 rounded-lg shadow-sm">
        <div class="flex-shrink-0 text-white">
          <component :is="skill.icon" class="h-5 w-5" />
        </div>
        <div class="ml-4">
          <p class="text-white font-medium">{{ skill.name }}</p>
          <p class="text-gray-400">{{ skill.level }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CodeBracketIcon, CircleStackIcon, GlobeAltIcon, CommandLineIcon, ServerStackIcon, CloudIcon } from '@heroicons/vue/24/outline'

export default {
  name: 'SkillsCard',
  data() {
    return {
      skills: [
        { name: 'Angular', level: 'Expert', icon: CodeBracketIcon },
        { name: 'PrimeNG', level: 'Expert', icon: CodeBracketIcon },
        { name: 'TailwindCSS', level: 'Intermediate', icon: CodeBracketIcon },
        { name: 'Vue.js', level: 'Intermediate', icon: CodeBracketIcon },
        { name: 'Node.js', level: 'Expert', icon: CodeBracketIcon },
        { name: 'TypeORM', level: 'Expert', icon: CircleStackIcon },
        { name: 'DevExpress', level: 'Intermediate', icon: CodeBracketIcon },
        { name: 'NPM', level: 'Expert', icon: CommandLineIcon },
        { name: 'Git', level: 'Expert', icon: CommandLineIcon },
        { name: 'NGINX', level: 'Intermediate', icon: GlobeAltIcon },
        { name: 'Linux (Ubuntu, Debian)', level: 'Intermediate', icon: ServerStackIcon },
        { name: 'REST/SOAP', level: 'Expert', icon: CloudIcon },
        { name: 'DMS/ECM (enaio)', level: 'Expert', icon: CircleStackIcon },
      ]
    }
  }
}
</script>