<template>
    <div class="bg-gray-900 p-4 rounded-lg shadow-md p-9">
      <h2 class="text-xl font-semibold text-white">Datenbanken</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
        <div v-for="(database, index) in databases" :key="index" class="flex items-center bg-zinc-950 p-4 rounded-lg shadow-sm">
          <div class="flex-shrink-0 text-white">
            <component :is="database.icon" class="h-4 w-4" />
          </div>
          <div class="ml-4">
            <p class="text-white font-medium">{{ database.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { CircleStackIcon } from '@heroicons/vue/24/outline'
  
  export default {
    name: 'DatabasesCard',
    data() {
      return {
        databases: [
          { name: 'PostgreSQL', icon: CircleStackIcon },
          { name: 'MSSQL', icon: CircleStackIcon },
          { name: 'MySQL', icon: CircleStackIcon },
          { name: 'Oracle SQL', icon: CircleStackIcon }
          // Weitere Skills hier
        ]
      }
    }
  }
  </script>