<template>
    <div class="bg-gray-900 p-4 rounded-lg shadow-md p-9">
      <h2 class="text-xl font-semibold text-white">Programmiersprachen</h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-4">
        <div v-for="(lang, index) in languages" :key="index" class="flex items-center bg-zinc-950 p-4 rounded-lg shadow-sm">
          <div class="flex-shrink-0 text-white">
            <component :is="lang.icon" class="h-4 w-4" />
          </div>
          <div class="ml-4">
            <p class="text-white font-medium">{{ lang.name }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { CircleStackIcon } from '@heroicons/vue/24/outline'
  
  export default {
    name: 'LanguagesCard',
    data() {
      return {
        languages: [
          { name: 'TypeScript', icon: CircleStackIcon },
          { name: 'JavaScript', icon: CircleStackIcon },
          { name: 'C#', icon: CircleStackIcon }
        ]
      }
    }
  }
  </script>